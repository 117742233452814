import React, { useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import { useWindowScroll } from 'react-use'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Icon from 'components/Icon'
import Footer from 'components/Footer'
import GlobalContext from 'context/GlobalStateProvider'
import StyleContext from 'context/StyleProvider'

import { WrapperStyled, BurgerMenuHeroContainer } from './styles'

const Wrapper = props => {
  const { children, style, hasFullHeight } = props
  const styleContext = useContext(StyleContext)
  const globalContext = useContext(GlobalContext)
  const burgerRef = useRef(null)
  const { y: scrollY } = useWindowScroll()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('769'))

  const burgerMenuProps = {
    onClick: globalContext?.handleOpen,
    openMenu: globalContext?.openMenu,
    ref: burgerRef,
    scrollY,
  }

  return (
    <WrapperStyled
      className={styleContext?.styleActivated?.split(' ').join('-')}
      style={style}
      hasFullHeight={hasFullHeight}
      openMenu={globalContext?.openMenu}
      animationLoaded={globalContext?.animationLoaded}
    >
      {children}
      <BurgerMenuHeroContainer {...burgerMenuProps}>
        <Icon
          type="burger-menu"
          className="burger-menu"
          openMenu={globalContext?.openMenu}
          isDesktop={isDesktop}
        />
      </BurgerMenuHeroContainer>
      <Footer />
    </WrapperStyled>
  )
}

Wrapper.propTypes = {
  isAnimated: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  globalContext: PropTypes.object,
}

export default Wrapper
