import styled from '@emotion/styled'

export const WrapperStyled = styled.div`
  width: 100%;
  position: ${props =>
    props.openMenu && props.animationLoaded ? 'fixed' : 'relative'};
  pointer-events: ${props => (props.openMenu ? 'none' : 'auto')};
  z-index: 2;
  height: 100%;
  transition: background-color 2s, color 1.5s;
  will-change: background-color, color;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primarygreen};
  top: ${props =>
    props.hasFullHeight ? 'calc(100vh - 77px)' : 'calc(481px - 77px)'};

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    top: ${props =>
      props.hasFullHeight ? 'calc(100vh - 152px)' : 'calc(688px - 152px)'};
  }

  .StickyHeader__AnimatedLine {
    background: ${props => props.theme.colors.white};
    transition: background 1.5s;
    will-change: background;
  }

  .GenericSectionSlice__Image {
    &:before,
    &:after {
      background-color: ${props => props.theme.colors.primarygreen};
      transition: background-color 1.5s;
      will-change: background-color;
    }
  }

  .GenericContainerList {
    a {
      color: ${props => props.theme.colors.primarygreen};
    }

    &:before {
      border-color: ${props => props.theme.colors.primarygreen};
      transition: border-color 1.5s;
      will-change: border-color;
    }
  }
  .GenericContainerList {
    &:before,
    &:after {
      background-color: ${props => props.theme.colors.primarygreen};
      transition: background-color 1.5s;
      will-change: background-color;
    }
  }
  .BackToAllProjects {
    color: ${props => props.theme.colors.primarygreen};
  }

  &.style-1 {
    background-color: ${props => props.theme.colors.lightblue};
    color: ${props => props.theme.colors.primarygreen};

    span.content__paragraph span.word:before {
      color: ${props => props.theme.colors.primarygreen};
    }

    .StickyHeader__AnimatedLine {
      background: ${props => props.theme.colors.primarygreen};
      transition: background 1.5s;
      will-change: background;
    }

    .GenericSectionSlice__Image {
      &:before,
      &:after {
        background-color: ${props => props.theme.colors.primarygreen};
        transition: background-color 1.5s;
        will-change: background-color;
      }
    }

    .GenericContainerList {
      &:before {
        border-color: ${props => props.theme.colors.primarygreen};
        transition: border-color 1.5s;
        will-change: border-color;
      }
    }

    .GenericContainerList {
      &:before,
      &:after {
        background-color: ${props => props.theme.colors.primarygreen};
        transition: background-color 1.5s;
        will-change: background-color;
      }
    }

    .BackToAllProjects {
      font-weight: bold;
      color: ${props => props.theme.colors.primarygreen};
    }
  }

  &.style-2 {
    background-color: ${props => props.theme.colors.primarygreen};

    .GenericSectionStyled,
    .BodyContentContainer,
    .IconContainerMain {
      a,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      span {
        color: ${props => props.theme.colors.lightblue};
        transition: color 1.5s;
        will-change: color;
      }
    }

    span.content__paragraph span.word:before {
      color: ${props => props.theme.colors.lightblue};
    }

    .StickyHeader__AnimatedLine {
      background: ${props => props.theme.colors.lightblue};
      transition: background 1.5s;
      will-change: background;
      
    }


    .Button {
      /* border: 3px solid ${props => props.theme.colors.lightblue}; */
      transition: 400ms;
      transition-property: border, background-color;
      will-change: border, background-color;

      &:hover {
        background-color: ${props => props.theme.colors.lightblue};

        span {
          transition: 0ms;
          color: ${props => props.theme.colors.primarygreen};
        }
      }
    }

    .GenericSectionSlice__Image {
      &:before,
      &:after {
        background-color: ${props => props.theme.colors.lightblue};
        transition: background-color 1.5s;
        will-change: background-color;
      }
    }

    .GenericContainerList {
      &:before {
        border-color: ${props => props.theme.colors.lightblue};
        transition: border-color 1.5s;
        will-change: border-color;
      }
    }
    .GenericContainerList {
      &:before,
      &:after {
        background-color: ${props => props.theme.colors.lightblue};
        transition: background-color 1.5s;
        will-change: background-color;
      }
    }
    .BackToAllProjects {
      color: ${props => props.theme.colors.lightblue};
    }
  }

  &.style-3 {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.primarygreen};

    span.content__paragraph span.word:before {
      color: ${props => props.theme.colors.primarygreen};
    }

    .StickyHeader__AnimatedLine {
      background: ${props => props.theme.colors.primarygreen};
      transition: background 1.5s;
      will-change: background;
    }

    .GenericSectionSlice__Image {
      &:before,
      &:after {
        background-color: ${props => props.theme.colors.primarygreen};
        transition: background-color 1.5s;
        will-change: background-color;
      }
    }

    .GenericContainerList {
      &:before {
        border-color: ${props => props.theme.colors.primarygreen};
        transition: border-color 1.5s;
        will-change: border-color;
      }
    }
    .GenericContainerList {
      &:before,
      &:after {
        background-color: ${props => props.theme.colors.primarygreen};
        transition: background-color 1.5s;
        will-change: background-color;
      }
    }
    .BackToAllProjects {
      color: ${props => props.theme.colors.primarygreen};
    }
  }

  .AnimatedLine__ProjectExcerpt {
    background: ${props => props.theme.colors.primarygreen};
    transition: background 1.5s;
    will-change: background;
  }
`

export const BurgerMenuHeroContainer = styled.div`
  display: ${props => (props.openMenu ? 'none' : 'block')};
  opacity: ${props => (props.scrollY < 152 ? 0 : 1)};
  transform: ${props =>
    props.scrollY < 152 ? 'translateY(30px)' : 'translateY(0px)'};
  position: absolute;
  height: 100%;
  top: -59px;
  right: 0;
  padding: 10px 0px;
  cursor: pointer;
  z-index: 6;
  transition: opacity 0.7s, transform 0.7s;
  z-index: 1;
  pointer-events: none;
  will-change: opacity, transform;

  .burger-menu {
    path:first-of-type,
    path:last-of-type {
      stroke: ${props => props.theme.colors.white};
    }
  }

  svg {
    position: sticky;
    top: 0px;
    right: 0;
    background-color: ${props => props.theme.colors.primarygreen};
    width: 75px;
    height: 75px;
    padding: 18.5px;
    z-index: 100000;
    pointer-events: all;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 90px;
      height: 90px;
      padding: 25px;
    }
  }
`

export const WrapperContent = styled.div`
  .WidthLimiter__SingleProjectPage {
    padding-bottom: 50px;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding-top: 230px;
      padding-bottom: 120px;
    }

    @media (max-width: ${props => props.theme.breakpoints.l}) {
      padding-bottom: 100px;
    }
  }
`

export const WrapperScroll = styled.div``
